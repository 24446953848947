import { useActive } from "../../data/pageActive";
import { main } from "../../domElements/domElements";
import { projectsData } from "./data/data";
import { fadeDom, handleMouseOver, letterAnimation } from "../../animations/animations";
import { multipleColors } from "../../data/colors";
import { openCloseCard } from "./card/card";

export const projects = (closeCard) => {
    const { projectsActive } = useActive();

    if ( projectsActive ) {
        main.textContent = '';

        //Creating DOM
        const projectsContainer = document.createElement( 'section' );
        const H2SpaceChat = document.createElement( 'h2' );
        const H2Panambi = document.createElement( 'h2' );
        const H2ReactWeather = document.createElement( 'h2' );
        const H2GameOn = document.createElement( 'h2' );
        const H2Camilleri = document.createElement('h2');



        // Adding father
        main.appendChild( projectsContainer );
        projectsContainer.append( H2SpaceChat, H2Panambi, H2ReactWeather, H2GameOn, H2Camilleri );
        H2SpaceChat.classList.add( 'project', 'space-chat-project' );
        H2Panambi.classList.add( 'project', 'panambi-project' );
        H2ReactWeather.classList.add( 'project', 'weather-project' );
        H2GameOn.classList.add( 'project', 'gameon-project' );
        H2Camilleri.classList.add( 'project', 'camilleri-project' );
        // Adding classes
        projectsContainer.classList.add( 'container-projects' );
        // SetAttributes


        letterAnimation( projectsData[ 0 ].name, 0, H2SpaceChat, multipleColors, handleMouseOver );
        letterAnimation( projectsData[ 1 ].name, 0, H2Panambi, multipleColors, handleMouseOver );
        letterAnimation( projectsData[ 2 ].name, 0, H2ReactWeather, multipleColors, handleMouseOver );
        letterAnimation( projectsData[ 3 ].name, 0, H2GameOn, multipleColors, handleMouseOver )
        letterAnimation( projectsData[ 4 ].name, 0, H2Camilleri, multipleColors, handleMouseOver );


        //AddEventListeners
        const allH2Projects = document.querySelectorAll( '.project' );
        allH2Projects.forEach( ( el, i ) => {
            openCloseCard( el, i )
        } )

        if(closeCard) {
            fadeDom(projectsContainer, 1, 0 )
        } else {
            fadeDom(projectsContainer, 3, 1.5 )
        }

    }
}
