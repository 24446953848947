import { normalMaterial } from "../material/material";
import * as THREE from "three";

export const contactGeometry = new THREE.Mesh(
    new THREE.SphereBufferGeometry( 0.15, 16, 32 ),
    normalMaterial( {
        flatShading: true
    } )
);

contactGeometry.position.x = -1.6;
contactGeometry.position.z = -3;
contactGeometry.position.y = -2;
