import { main } from "../../domElements/domElements";
import { skills } from "./data/data";
import { fadeDom, handleMouseOver } from "../../animations/animations";
import { multipleColors } from "../../data/colors";

export const stack = () => {
    // Removing all content in main
    main.textContent = '';
    // Creating DOM
    const stackContainer = document.createElement('section');
    const stackSubcontainer = document.createElement('div');
    // Adding Father
    main.appendChild(stackContainer);
    stackContainer.appendChild(stackSubcontainer);

    skills.forEach(el => {
        // Creating DOM
        const skillSpan = document.createElement('span');
        //Adding Father
        stackSubcontainer.appendChild(skillSpan)
        //Adding Classes
        stackContainer.classList.add('stack-container')
        // Adding Content
        skillSpan.textContent = el;
        //Animation
        handleMouseOver(skillSpan, multipleColors);
        fadeDom( stackContainer, 3, 2 );
    });
}
