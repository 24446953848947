import emailjs from '@emailjs/browser'
import { handleSendMessage } from "../../../animations/animations";
import { main } from "../../../domElements/domElements";

export const sendEmail = async ( inputForName, inputForEmail, inputForSubject, inputForMessage ) => {
    try {
        let tempParams = {
            to_name: 'Alexis',
            from_name: inputForName.value,
            from_mail: inputForEmail.value,
            subject: inputForSubject.value,
            message: inputForMessage.value,
        }

        await emailjs.send( 'service_2wdmhbd', 'template_wq8wnrm', tempParams, 'x3bA9gysX1X8daoxp' );
        handleSendMessage( main, 'Your e-mail has been sent!', true );
    } catch ( err ) {
        handleSendMessage( main, 'There was an error, please try again later', false );
        throw Error( err );
    }

}


