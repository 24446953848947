import { header } from "../../domElements/domElements";
import { pageActive } from "../../data/pageActive";
import { selectedSection } from "./controllers/selectedSection";


export const navBar = () => {
    const nav = document.createElement( 'nav' );
    header.appendChild( nav );

    pageActive.map( ( { description }, index ) => {
        //Creating DOM
        const spanBar = document.createElement( 'span' );
        //Adding className
        spanBar.classList.add( 'spanNav' );
        // Adding conditional class so spanActive is set by default in "home"
        index === 4 && spanBar.classList.add('spanActive')
        //Add Content
        spanBar.textContent = description;
        //Add Father
        nav.appendChild( spanBar );
    } );

    //AddEventListeners
    const allSpanNav = document.querySelectorAll( '.spanNav' );
    allSpanNav.forEach( (span, i) => span.addEventListener( 'click', () => selectedSection( pageActive, span, i, allSpanNav ) ) );
}









