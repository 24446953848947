import { main } from '../../domElements/domElements';
import { profile } from './data/data';
import { fadeDom, handleMouseOver, letterAnimation } from "../../animations/animations";
import { useActive } from "../../data/pageActive";
import { multipleColors } from "../../data/colors";

export const home = (firstLoad) => {

    const { homeActive } = useActive();

    if ( homeActive) {
        // Deleting all existing content in main
        main.textContent = '';
        // Creating Dom
        const homeContainer = document.createElement( 'section' );
        const h2Name = document.createElement( 'h2' );
        const h2Skill = document.createElement( 'h2' );
        // Adding class
        homeContainer.classList.add('container-home')
        // Adding father
        main.appendChild( homeContainer );
        homeContainer.append( h2Name, h2Skill );
        // Adding content with animation.
        letterAnimation( profile, 0, h2Name, multipleColors, handleMouseOver );
        letterAnimation( profile, 1, h2Skill, multipleColors, handleMouseOver );

        // Adding fade animation

        if(firstLoad) fadeDom(homeContainer, 1, 0);
        if(!firstLoad) fadeDom(homeContainer, 3, 2);

    }
}


