import { gsap } from "gsap";
import { homeGeometry } from "../geometrys/homeGeometry";
import { songActive } from "../../domHandle/ui/audio/audio";

// Rotate geometrys
export const rotateY = ( geometry, elapsedTime, speed, fasterSpeed ) => {
    if(!songActive) {
        geometry.rotation.y = elapsedTime * speed;
    } else {
        geometry.rotation.y = elapsedTime * fasterSpeed;
    }
}

// Movement camera

export const moveCamera = ( camera, home, aboutMe, stack, projects, contact ) => {
    if ( home ) {
        gsapFromTo( homeGeometry, 0, 0, 0 );
        gsapFromTo( camera, 0, 0, 1 )
    } else if ( aboutMe ) {
        gsapFromTo( camera, -4, 0, 1 )
    } else if ( stack ) {
        gsapFromTo( homeGeometry, 0, 3, 0 );
        gsapFromTo( camera, 0, 0, -4 )
    } else if ( projects ) {
        gsapFromTo( camera, -4, 0, -4 )
    } else if ( contact ) {
        gsapFromTo( camera, -2, -2, -2 )
    }
}

const gsapFromTo = ( camera, x, y, z ) => {
    gsap.fromTo( camera.position, { x: camera.position.x, y: camera.position.y, z: camera.position.z }, {
        x: x,
        y: y,
        z: z,
        duration: 3
    } )
}
