import * as THREE from "three";
import { particleMaterial } from "../material/material";
import { particlesTexture } from "../texture/texture";


const particleGeometry = new THREE.BufferGeometry();
const count = 50000;
const positions = new Float32Array( count * 3 );

for ( let i = 0; i < count * 3; i++ ) {
    positions[ i ] = ( Math.random() - 0.5 ) * 20
}

particleGeometry.setAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );

particleMaterial.size = 0.04;
particleMaterial.transparent = true;
particleMaterial.alphaMap = particlesTexture;
particleMaterial.color = new THREE.Color( 'white' );
particleMaterial.depthWrite = false;
particleMaterial.sizeAttenuation = true;

export const particles = new THREE.Points( particleGeometry, particleMaterial );







