import './scss/app.scss'
import { threeJS } from "./threeJS/threeJS";
import { navBar } from "./domHandle/ui/navbar/navbar";
import { home } from "./domHandle/pages/home/home";
import { audioPage } from "./domHandle/ui/audio/audio";

const firstLoad = true;

// Three JS
threeJS();
// Navbar
navBar();
// Home
home(firstLoad);
// Audio
// audioPage();






