import { gsap } from "gsap";
import { contact } from "../pages/contact/contact";


// This function recieve a string inside an array and create spans for each letter
export const letterAnimation = ( array, position, father, colorArray, callBack ) => {
    for ( let i = 0; i < array[ position ].length; i++ ) {
        const words = array[ position ][ i ].split( ' ' );
        const span = document.createElement( 'span' );

        words.length === 2 && span.classList.add( 'spacing' );

        span.textContent = array[ position ][ i ];
        father.appendChild( span );

        callBack( span, colorArray );
    }
}


export const fadeDom = ( element, duration, delay ) => {
    gsap.fromTo( element, { opacity: 0 }, { opacity: 1, duration: duration, delay: delay } );
}


export const handleMouseOver = ( domElement, colorArray ) => {
    domElement.addEventListener( 'mouseover', () => {
        let randomColor = Math.round( Math.random() * 3 );
        gsap.fromTo( domElement, {
            scale: 1,
        }, { scale: 1.5, duration: 0.5, color: colorArray[ randomColor ] } )
        setTimeout( () => {
            gsap.to( domElement, { scale: 1, duration: 0.5, color: '#fff' } )
        }, 1000 )
    } )
}

export const handleTimeOut = ( domElement, colorArray ) => {
    setInterval( () => {
        let randomColor = Math.round( Math.random() * 3 );
        gsap.fromTo( domElement, {
            scale: 1,
        }, { scale: 1.2, duration: 0.5, color: colorArray[ randomColor ] } )
        setTimeout( () => {
            gsap.to( domElement, { scale: 1, duration: 0.5, color: '#fff' } )
        }, 1000 )
    }, 2000 )
}

export const handleSendMessage = ( domElement, message, state ) => {
    gsap.to( domElement, { opacity: 0, duration: 0.5 } )
    setTimeout( () => {
        gsap.to( domElement, { opacity: 1, duration: 0.5 } )
        gsap.to( domElement, { opacity: 0, duration: 0.5, delay: 4 } )
        gsap.to( domElement, { opacity: 1, duration: 0.5, delay: 4.5 } )
        domElement.textContent = '';
        const stateMessage = document.createElement( 'p' );
        state ? stateMessage.classList.add( 'success-stateMessage' ) : stateMessage.classList.add( 'error-stateMessage' );
        stateMessage.textContent = message
        domElement.appendChild( stateMessage );
    }, 600 )


    setTimeout( () => {
        const stateToAppear = true;
        contact( stateToAppear );
    }, 5000 )
}
