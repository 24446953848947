import { home } from "../../../pages/home/home";
import { moveCamera } from "../../../../threeJS/animations/animations";
import { camera } from "../../../../threeJS/config";
import { aboutMe } from "../../../pages/aboutMe/aboutMe";
import { useActive } from "../../../data/pageActive";
import { stack } from "../../../pages/stack/stack";
import { disposeGeometrys } from "../../../../threeJS/controllers/disposeGeometrys";
import { projects } from "../../../pages/projects/projects";
import { contact } from "../../../pages/contact/contact";

export const selectedSection = ( data, span, indexSpan, allSpanNav ) => {

    if ( !span.classList.contains( 'spanActive' ) ) {
        // Eliminate classes to switch the active Span
        allSpanNav.forEach( ( span ) => span.classList.remove( 'spanActive' ) );
        // Changing the state of the inactive view
        data.forEach( ( el, i ) => {
            if ( i !== indexSpan ) {
                el.status = false;
            }
        } );
        // Changing the state of the active view and adding the class.
        data[ indexSpan ].status = true;
        span.classList.add( 'spanActive' );
        // Showing corresponding section clicked
        showDom();
    }
}

const showDom = () => {
    const { aboutMeActive, contactActive, homeActive, projectsActive, stackActive } = useActive();
    let song = document.querySelector( '.song' );
    // disposeGeometrys( homeActive, aboutMeActive, stackActive, projectsActive, contactActive )

    moveCamera( camera, homeActive, aboutMeActive, stackActive, projectsActive, contactActive )

    homeActive && home();
    aboutMeActive && aboutMe();
    stackActive && stack();
    projectsActive && projects();
    contactActive && contact();

    if ( window.innerWidth < 992 && ( projectsActive || contactActive || aboutMeActive ) ) {
        song.style.display = 'none';
    } else {
        song.style.display = 'flex'
    }

    if ( window.innerWidth < 340 ) {
        song.style.display = 'none';
    }

    window.addEventListener( 'resize', () => {
        if ( window.innerWidth < 992 && ( projectsActive || contactActive || aboutMeActive ) ) {
            song.style.display = 'none';
        } else {
            song.style.display = 'flex'
        }
    } )
}

