import { useActive } from "../../data/pageActive";
import { main } from "../../domElements/domElements";
import { fadeDom, handleMouseOver, letterAnimation } from "../../animations/animations";
import { greeting, myName, presentation } from "./data/data";
import { multipleColors } from "../../data/colors";

export const aboutMe = () => {

    const { aboutMeActive } = useActive();

    if ( aboutMeActive ) {
        // Deleting all existing content in main
        main.textContent = '';
        // Creating Dom
        const aboutMeContainer = document.createElement( 'section' );
        const titleContainer = document.createElement( 'div' );
        const descriptionContainer = document.createElement( 'div' );
        const cvContainer = document.createElement( 'div' );
        const phraseContainer = document.createElement( 'div' );
        const phraseParagraph = document.createElement( 'p' );
        const cvParagraph = document.createElement( 'p' );
        const cvAnchor = document.createElement( 'a' );
        const h2Hi = document.createElement( 'h2' );
        const h2MyName = document.createElement( 'h2' );
        const descriptionParagraph = document.createElement( 'p' );

        greeting.forEach( el => {
            // Creating Dom
            const spanGreeting = document.createElement( 'span' );
            // Adding content
            spanGreeting.textContent = el;
            // Adding Father
            h2Hi.appendChild( spanGreeting );
        } )
        // Adding class
        aboutMeContainer.classList.add( 'aboutMe-container' );
        titleContainer.classList.add( 'title' );
        descriptionContainer.classList.add( 'description' )
        cvContainer.classList.add( 'cv' );
        phraseContainer.classList.add('phrase');
        h2Hi.classList.add( 'greeting' );
        // Adding father
        main.appendChild( aboutMeContainer );
        aboutMeContainer.append( titleContainer, descriptionContainer, cvContainer, phraseContainer );
        titleContainer.append( h2Hi, h2MyName );
        descriptionContainer.appendChild( descriptionParagraph );
        cvContainer.append( cvParagraph, cvAnchor );
        phraseContainer.appendChild( phraseParagraph );
        // Set Attribute
        cvAnchor.setAttribute( 'href', 'https://drive.google.com/file/d/1VQeVO1V7w7Olbfubv-Wu0BrZhUXwbwpK/view?usp=sharing' );
        cvAnchor.setAttribute( 'target', '_blank' );
        // Adding content
        descriptionParagraph.innerHTML = presentation;
        cvParagraph.textContent = 'Download my CV';
        cvAnchor.textContent = 'HERE';
        phraseParagraph.textContent = `"THERE'S NO FAILURE, ONLY FEEDBACK"`
        // Adding content with animation.
        letterAnimation( myName, 0, h2MyName, multipleColors, handleMouseOver );

        // Adding animation fade
        fadeDom( aboutMeContainer, 3, 2 );

    }
}
