import { useActive } from "../../data/pageActive";
import { main } from "../../domElements/domElements";
import {
    fadeDom,
    handleTimeOut,
    letterAnimation,
} from "../../animations/animations";
import { buttonText, contactMe, description } from "./data/data";
import { sendEmail } from "./controllers/sendEmail";
import { handleErrors, validateForm } from "./controllers/validateForm";
import { multipleColors } from "../../data/colors";

export const contact = (state) => {
    const { contactActive } = useActive();

    if ( contactActive ) {
        // Deleting all existing dom in main
        main.textContent = '';
        // Creating DOM
        const contactContainer = document.createElement( 'section' ),
            containerTitle = document.createElement( 'div' ),
            titleContact = document.createElement( 'div' ),
            subtitleContact = document.createElement( 'div' ),
            subtitleContactP = document.createElement( 'p' ),
            formContainer = document.createElement( 'form' ),
            ulContainerForm = document.createElement( 'ul' ),
            liFormName = document.createElement( 'li' ),
            liFormEmail = document.createElement( 'li' ),
            liForSubject = document.createElement( 'li' ),
            liForMessage = document.createElement( 'li' ),
            liForButton = document.createElement( 'li' ),
            divForSocial = document.createElement( 'div' ),
            aForSocialGit = document.createElement( 'a' ),
            aForSocialLinkedin = document.createElement( 'a' ),
            aForSocialWs = document.createElement( 'a' ),
            imgForSocialGit = document.createElement( 'img' ),
            imgForSocialLinkedin = document.createElement( 'img' ),
            imgForSocialWs = document.createElement( 'img' ),
            buttonSend = document.createElement( 'button' ),
            inputForName = document.createElement( 'input' ),
            inputForEmail = document.createElement( 'input' ),
            inputForSubject = document.createElement( 'input' ),
            inputForMessage = document.createElement( 'textarea' ),
            labelForName = document.createElement( 'label' ),
            labelForEmail = document.createElement( 'label' ),
            labelForSubject = document.createElement( 'label' ),
            labelForMessage = document.createElement( 'label' );
        // Adding classes
        contactContainer.classList.add( 'container-contact' );
        containerTitle.classList.add( 'container-title' );
        titleContact.classList.add( 'title-contact' );
        subtitleContact.classList.add( 'subtitle-contact' );
        liFormName.classList.add( 'name-form-li' );
        liFormEmail.classList.add( 'email-form-li' );
        liForSubject.classList.add( 'subject-form-li' );
        liForMessage.classList.add( 'message-form-li' );
        liForButton.classList.add( 'button-form-li' );
        // Adding father
        main.appendChild( contactContainer );
        contactContainer.append( containerTitle, formContainer );
        contactContainer.append( containerTitle );
        containerTitle.append( titleContact, subtitleContact );
        subtitleContact.append( subtitleContactP );
        formContainer.append( ulContainerForm );
        ulContainerForm.append( liFormName, liFormEmail, liForSubject, liForMessage, liForButton );
        liFormName.append( inputForName, labelForName );
        liFormEmail.append( inputForEmail, labelForEmail );
        liForSubject.append( inputForSubject, labelForSubject );
        liForMessage.append( inputForMessage, labelForMessage );
        liForButton.append( divForSocial, buttonSend );
        divForSocial.append( aForSocialGit, aForSocialLinkedin, aForSocialWs );
        aForSocialGit.append( imgForSocialGit );
        aForSocialLinkedin.append( imgForSocialLinkedin );
        aForSocialWs.append( imgForSocialWs );
        // SetAttributes
        formContainer.setAttribute( 'autocomplete', 'off' );
        inputForName.setAttribute( 'id', 'js-input-name' );
        inputForEmail.setAttribute( 'id', 'js-input-email' );
        inputForSubject.setAttribute( 'id', 'js-input-subject' );
        inputForMessage.setAttribute( 'id', 'js-input-message' );
        inputForName.setAttribute( 'placeholder', 'Name' );
        inputForEmail.setAttribute( 'placeholder', 'Email' );
        inputForSubject.setAttribute( 'placeholder', 'Subject' );
        inputForMessage.setAttribute( 'placeholder', 'Message' );
        labelForName.setAttribute( 'for', 'js-input-name' );
        labelForEmail.setAttribute( 'for', 'js-input-email' );
        labelForSubject.setAttribute( 'for', 'js-input-subject' );
        labelForMessage.setAttribute( 'for', 'js-input-message' );
        aForSocialGit.setAttribute( 'href', 'https://github.com/ANGdesarrollo' );
        aForSocialLinkedin.setAttribute( 'href', 'https://www.linkedin.com/in/alexis-graff-front-end/' );
        aForSocialWs.setAttribute( 'href', 'https://api.whatsapp.com/send?phone=542915343707' )
        aForSocialGit.setAttribute( 'target', '_blank' );
        aForSocialLinkedin.setAttribute( 'target', '_blank' );
        aForSocialWs.setAttribute( 'target', '_blank' );
        imgForSocialGit.setAttribute( 'src', '/images/github.png' );
        imgForSocialLinkedin.setAttribute( 'src', '/images/linkedin.png' );
        imgForSocialWs.setAttribute( 'src', '/images/whatsapp.png' );
        // Setting the content
        buttonSend.textContent = buttonText;
        subtitleContact.textContent = description;

        letterAnimation( contactMe, 0, titleContact, multipleColors, handleTimeOut )

        //AddEventListeners
        buttonSend.addEventListener( 'click', async ( e ) => {
            e.preventDefault();
            const validate = validateForm( inputForName, inputForEmail, inputForSubject, inputForMessage );
            const noErrors = handleErrors( validate, liFormName, liFormEmail, liForSubject, liForMessage );

            if ( noErrors ) {
                validate && await sendEmail( inputForName, inputForEmail, inputForSubject, inputForMessage );
            }
        } )


        // Fade animation
        state ? fadeDom( contactContainer, 1, 0) : fadeDom( contactContainer, 3, 1.5 );
    }
}
