import { validateEmail, validateField } from "../validations/useValidations";

export const validateForm = ( inputForName, inputForEmail, inputForSubject, inputForMessage, father ) => {
    const dataError = {
        name: false,
        email: false,
        subject: false,
        message: false,
    }

    const validateNameField = validateField( inputForName.value );
    const validateEmailField = validateEmail( inputForEmail.value );
    const validateSubjectField = validateField( inputForSubject.value );
    const validateMessageField = validateField( inputForMessage.value );

    if ( !validateNameField ) {

        return { ...dataError, name: true }
    }
    if ( !validateEmailField ) return { ...dataError, email: true }
    if ( !validateSubjectField ) return { ...dataError, subject: true }
    if ( !validateMessageField ) return { ...dataError, message: true }

    return validateNameField && validateEmailField && validateSubjectField && validateMessageField

}

export const handleErrors = ( data, domName, domEmail, domSubject, domMessage ) => {
    const domError = document.createElement( 'p' );
    domError.classList.add('error-form');

    const domErrors = document.querySelectorAll('.error-form');
    const inputErrors = document.querySelectorAll('.error-input');
    domErrors.forEach((el) => el.textContent = '');
    inputErrors.forEach(el => el.classList.remove('error-input'));

    if ( data.name ) {
        domName.firstChild.classList.add('error-input');
        domError.textContent = '*Complete this field';
        domName.appendChild( domError );
    } else if ( data.email ) {
        domEmail.firstChild.classList.add('error-input');
        domError.textContent = '*Invalid format Email';
        domEmail.appendChild( domError );
    } else if ( data.subject ) {
        domSubject.firstChild.classList.add('error-input');
        domError.textContent = '*Complete this field';
        domSubject.appendChild( domError );
    } else if ( data.message ) {
        domMessage.firstChild.classList.add('error-input');
        domError.textContent = '*Complete this field';
        domMessage.appendChild( domError );
    } else {
        return true;
    }
}
