import { homeGeometry } from "./geometrys/homeGeometry";
import { clock } from "./clock/clock";
import { particles } from "./geometrys/particles";
import { camera, resize, scene, sizes, renderer, resizeGeometrys } from "./config";
import { aboutMeGeometry } from "./geometrys/aboutMeGeometry";
import { stackGeometry } from "./geometrys/stackGeometry";
import { contactGeometry } from "./geometrys/contactGeometry";
import { rotateY } from "./animations/animations";

export const threeJS = () => {
    camera.position.z = 1;

//**
//* Renderer config
//*
    renderer.setSize( sizes.width, sizes.height );
    renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) );

//**
//* Scene
//**

    scene.add( particles, homeGeometry, aboutMeGeometry, stackGeometry, contactGeometry, camera );

//**
//* Resize config
//**

    window.addEventListener( 'resize', () => resize( sizes, camera, renderer ) );
    resizeGeometrys( homeGeometry, aboutMeGeometry, stackGeometry, contactGeometry )


//**
//* Tick animation
//**
    const tick = () => {
        const elapsedTime = clock.getElapsedTime();

        rotateY( homeGeometry, elapsedTime, 0.1, 0.15 );
        rotateY( aboutMeGeometry, elapsedTime, 0.1, 0.15 );
        rotateY( stackGeometry, elapsedTime, 0.1, 0.15 );
        rotateY( contactGeometry, elapsedTime, 0.1, 0.15 );
        rotateY( particles, -elapsedTime, 0.009, 0.050 );

        renderer.render( scene, camera );

        window.requestAnimationFrame( tick );

    }
    tick();
}





