export const pageActive = [
    { status: false, description: 'CONTACT' },
    { status: false, description: 'PROJECTS' },
    { status: false, description: 'STACK' },
    { status: false, description: 'ABOUT ME' },
    { status: true, description: 'HOME' },
]

export const useActive = () => {
    const homeActive = pageActive[ 4 ].status;
    const aboutMeActive = pageActive[ 3 ].status;
    const stackActive = pageActive[ 2 ].status;
    const projectsActive = pageActive[ 1 ].status;
    const contactActive = pageActive[ 0 ].status;

    return {
        homeActive,
        aboutMeActive,
        stackActive,
        projectsActive,
        contactActive
    }
}

