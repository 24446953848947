import { fadeDom } from "../../../animations/animations";
import { multipleColors } from "../../../data/colors";
import { projectsData } from "../data/data";
import { gsap } from "gsap";
import { main } from "../../../domElements/domElements";
import { projects } from "../projects";

export const card = ( domFather, image, dataDescription, classList, url ) => {
    let randomColor = Math.round( Math.random() * 3 );
    const cardContainer = document.createElement( 'div' );
    const cardPhotoContainer = document.createElement( 'div' );
    const cardPhoto = document.createElement( 'img' );
    const generalDescriptionContainer = document.createElement( 'div' );
    const generalDescription = document.createElement( 'ul' );
    const openIcon = document.createElement( 'span' );

    cardContainer.style.border = `2px solid ${ multipleColors[ randomColor ] }`
    cardContainer.classList.add( `card-${ classList }` )
    cardContainer.classList.add( 'card' );
    openIcon.classList.add('open-icon')

    domFather.appendChild( cardContainer );
    cardContainer.append( cardPhotoContainer, generalDescriptionContainer );
    cardPhotoContainer.append( cardPhoto, openIcon );
    generalDescriptionContainer.appendChild( generalDescription );

    cardPhoto.setAttribute( 'src', image );
    dataDescription.forEach( el => {
        const liStack = document.createElement( 'li' );
        generalDescription.appendChild( liStack );
        liStack.textContent = el;
    } )
    openIcon.textContent = '+';

    fadeDom( cardContainer, 1, 0 );

    //Expand
    removeCard( openIcon, cardContainer, url )

    return cardContainer;
}

export const openCloseCard = ( el, i ) => {
    el.addEventListener( 'mouseenter', () => card( el, projectsData[ i ].img, projectsData[ i ].stack, i, projectsData[ i ].url ) );
    el.addEventListener( 'mouseleave', () => {
        const cardSelected = document.querySelector( `.card-${ i }` );
        cardSelected.remove();
    } )
}

const removeCard = ( button, cardContainer, url ) => {
    let width = window.innerWidth;
    window.addEventListener('resize', () => width = window.innerWidth);

    if(width < 576) {
       button.addEventListener('click', () => {
            window.open(url);
        })
    } else {
        const projects = document.querySelector( '.container-projects' );
        button.addEventListener( 'click', () => {
            gsap.to( cardContainer, { duration: 1, opacity: 0 } )
            setTimeout( () => {
                cardContainer.remove();
                projects.remove();
            }, 1050 );
            iframe( url )
        } )
    }

}

const iframe = ( url ) => {
    const containerIframe = document.createElement( 'div' );
    const subContaienrIframe = document.createElement( 'div' );
    const iframe = document.createElement( 'iframe' );
    const closeIframe = document.createElement( 'button' );
    const openLink = document.createElement( 'a' );
    const openLinkImg = document.createElement( 'img' );

    main.append( containerIframe );
    containerIframe.append( subContaienrIframe )
    subContaienrIframe.append( iframe, closeIframe, openLink );
    openLink.appendChild( openLinkImg )

    gsap.fromTo( containerIframe, {
        opacity: 0,
    }, {
        opacity: 1,
        duration: 2,
        delay: 0.8
    } );

    closeIframe.textContent = 'X'
    iframe.setAttribute( 'src', url );
    openLink.setAttribute( 'href', url );
    openLink.setAttribute( 'target', '_blank' );
    openLinkImg.setAttribute( 'src', './images/red.png' );
    iframe.classList.add( 'iframe' );
    containerIframe.classList.add( 'container-iframe' );

    closeIframe.addEventListener( 'click', () => {
        gsap.to( containerIframe, {
            opacity: 0,
            duration: 1
        } )

        setTimeout( () => {
            containerIframe.remove();
            projects( true );
        }, 1000 )
    } )
}


