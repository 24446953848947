import { homeGeometry } from "../geometrys/homeGeometry";
import { aboutMeGeometry } from "../geometrys/aboutMeGeometry";
import { stackGeometry } from "../geometrys/stackGeometry";
import { contactGeometry } from "../geometrys/contactGeometry";

export const resize = ( sizes, camera, renderer ) => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize( sizes.width, sizes.height )
    renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) )
}

export const resizeGeometrys = ( homeGeometry, aboutMeGeometry, stackGeometry, contactGeometry ) => {
    const screenWidth = window.innerWidth;
    const scale = screenWidth < 992 ? 0.5 : 1;
    if ( window.innerWidth < 992 ) {
        homeGeometry.scale.set( scale, scale, scale );
        aboutMeGeometry.scale.set( scale, scale, scale );
        stackGeometry.scale.set( scale, scale, scale );
        contactGeometry.scale.set( scale, scale, scale );
    }

    window.addEventListener( 'resize', () => {
        const screenWidth = window.innerWidth;
        const scale = screenWidth < 992 ? 0.5 : 1;

        // Aplica la escala a la geometría
        homeGeometry.scale.set( scale, scale, scale );
        aboutMeGeometry.scale.set( scale, scale, scale );
        stackGeometry.scale.set( scale, scale, scale );
        contactGeometry.scale.set( scale, scale, scale );

    } )
}







