
export let songActive = false;

export const audioPage = () => {
    const container = document.createElement( 'div' );
    const playPause = document.createElement( 'img' );
    const volumeUpButton = document.createElement( 'img' );
    const volumeDownButton = document.createElement( 'img' );
    const audio = document.createElement( 'audio' );
    const imagePlay = './images/play.png';
    const imagePause = './images/pause.png';

    // Adding fathers
    document.body.appendChild( container );
    container.append(volumeUpButton, audio, playPause, volumeDownButton );

    // Adding classes
    container.classList.add('song');

    // Crear elemento de audio
    audio.src = './audio/audio.mp3';
    playPause.src = './images/play.png';

// Crear botón de reproducción

    playPause.addEventListener( 'click', () => {
        songActive = !songActive;

        if(!songActive) {
            playPause.src = imagePlay;
            audio.pause();
            container.classList.remove('animated')
        } else {
            playPause.src = imagePause;
            container.classList.add('animated');
            audio.play();
        }
    } )

// Crear botón para subir volumen
    volumeUpButton.src = './images/anadir.png';
    volumeUpButton.addEventListener( 'click', () => {
        if ( audio.volume < 1.0 ) {
            audio.volume += 0.1;
        }
    } );

// Crear botón para bajar volumen

    volumeDownButton.src = './images/menos.png';
    volumeDownButton.addEventListener( 'click', () => {
        if ( audio.volume > 0.0 ) {
            audio.volume -= 0.1;
        }
    } );
}
