const imgReactForecast = './images/projects/ReactWeather.webp'
const imgPanambi = './images/projects/Panambi.webp'
const imgGameOn = './images/projects/GameOn.webp'
const imgSpaceChat = './images/projects/SpaceChat.webp'
const imgCamilleri = './images/projects/CamilleriConstrucciones.webp'

export const projectsData = [
    {
        name: [ 'SPACE CHAT' ],
        img: imgSpaceChat,
        stack: [
            'PERSONAL PROJECT',
            'MONGODB',
            'REDIS',
            'NODE JS',
            'EXPRESS',
            'SOCKET IO',
            'PASSPORT',
            'REACT JS',
            'REDUX',
            'SCSS'
        ],
        url: 'https://front-end-socket-chat.vercel.app'
    },
    {
        name: [ 'PANAMBÍ CONSULTORIA' ],
        img: imgPanambi,
        stack: [
            'FREELANCE PROJECT',
            'REACT JS',
            'SCSS',
            'EMAIL JS',
        ],
        url: 'https://www.panambiconsultoria.com'
    },
    {
        name: [ 'REACT FORECAST' ],
        img: imgReactForecast,
        stack: [
            'PERSONAL PROJECT',
            'REACT JS',
            'AXIOS',
            'WEATHER API',
            'MATERIAL UI'
        ],
        url: 'https://react-forecast-phi.vercel.app'

    },
    {
        name: [ 'GAME ON ECOMMERCE' ],
        img: imgGameOn,
        stack: [
            'PERSONAL PROJECT',
            'REACT JS',
            'FIREBASE',
            'SCSS',
            'EMAIL JS',
        ],
        url: 'https://react-final-coder.vercel.app/'
    },
    {
        name: [ 'CAMILLERI CONSTRUCCIONES' ],
        img: imgCamilleri,
        stack: [
            'FREELANCE PROJECT',
            'REACT JS',
            'NODE JS',
            'PM2',
            'EMAIL JS',
        ],
        url: 'https://camillericonstrucciones.com'
    }

]
